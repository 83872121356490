.target {
  width: 100%;
  height: 100%;

  > * {
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-width: 0;
    border-style: solid;
    border-color: rgba(224, 25, 25, 0.7);
  }

  .lt {
    left: 0;
    top: 0;
    border-left-width: 6px;
    border-top-width: 6px;
  }

  .rt {
    right: 0;
    top: 0;
    border-right-width: 6px;
    border-top-width: 6px;
  }

  .lb {
    left: 0;
    bottom: 0;
    border-left-width: 6px;
    border-bottom-width: 6px;
  }

  .rb {
    right: 0;
    bottom: 0;
    border-right-width: 6px;
    border-bottom-width: 6px;
  }
}
