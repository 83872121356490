.sign-in-form {
  display: grid;
  grid-template-columns: 10rem 14rem;
  gap: 0 2rem;
  align-items: center;
  grid-template-areas:
    "usernameLabel username"
    "stateOfTestingLabel stateOfTesting"
    ". stateOfTestingValidation"
    "vendorOfTestingLabel vendorOfTesting"
    ". vendorOfTestingValidation"
    "scannerModeLabel scannerMode"
    "languageLabel language";

  @media (max-width: 570px) {
    grid-template-columns: 10rem;
    grid-gap: 0.5 0.5rem;
  }

  .usernameLabel {
    grid-area: usernameLabel;
    margin-bottom: 1rem;
  }

  .username {
    grid-area: username;
    margin-bottom: 1rem;
  }

  .stateOfTestingLabel {
    grid-area: stateOfTestingLabel;
  }

  .stateOfTesting {
    grid-area: stateOfTesting;
  }

  .stateOfTestingValidation {
    grid-area: stateOfTestingValidation;
  }

  .vendorOfTestingLabel {
    grid-area: vendorOfTestingLabel;
  }

  .vendorOfTesting {
    grid-area: vendorOfTesting;
  }

  .vendorOfTestingValidation {
    grid-area: vendorOfTestingValidation;
  }



  .scannerModeLabel {
    grid-area: scannerModeLabel;
  }
  .scannerMode {
    width: 250px;
    grid-area: scannerMode;
  }
  .languageLabel {
    grid-area: languageLabel;
  }

  .language {
    grid-area: language;
  }
}

.target-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 26rem;
  height: 26rem;
  margin-top: 10rem;
}

.floating-header {
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  padding: 2rem;
}

.settings {
  margin-bottom: 20px;
  button {
    float: right;
  }
}
