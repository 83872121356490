.form-header {
  display: grid;
  grid-template-columns: max-content max-content 1fr max-content max-content;
  grid-template-rows: max-content;
  gap: 0 1rem;
  grid-template-areas:
    "labelSpecimenId specimenId . labIdLabel labId"
    ". . . labAccountLabel labAccount"
    ". . . labPanelIdLabel labPanelId";

  > * {
    white-space: nowrap;
  }

  @media (max-width: 570px) {
    grid-template-columns: max-content max-content;
    grid-template-areas:
      "labelSpecimenId specimenId"
      "labIdLabel labId"
      "labAccountLabel labAccount"
      "labPanelIdLabel labPanelId";
  }

  .labelSpecimenId {
    grid-area: labelSpecimenId;
  }

  .specimenId {
    grid-area: specimenId;
  }

  .labIdLabel {
    grid-area: labIdLabel;
  }

  .labId {
    grid-area: labId;
  }

  .labAccountLabel {
    grid-area: labAccountLabel;
  }

  .labAccount {
    grid-area: labAccount;
  }

  .labPanelIdLabel {
    grid-area: labPanelIdLabel;
  }

  .labPanelId {
    grid-area: labPanelId;
  }
}

.form-body {
  display: grid;
  width: 100%;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content;
  gap: 0.125rem 5rem;
  grid-template-areas:
    "donorInformation donorInformation"
    "idLabel id"
    "lastNameLabel lastName"
    "firstNameLabel firstName"
    "idVerifiedLabel idVerified"
    "reasonForTestLabel reasonForTest"
    "phoneNumberLabel phoneNumber"
    "dateOfBirthLabel dateOfBirth"
    "collectionInformation collectionInformation"
    "monitorNameLabel monitorName"
    "collectionDateTimeLabel collectionDateTime"
    "employerLabel medicalReviewOfficerLabel"
    "employer medicalReviewOfficer"
    "note note"
    "nameLabel name"
    "signatureLabel signature"
    ". signatureValidation";

  @media (max-width: 570px) {
    gap: 0.125rem 1rem;
  }

  > * {
    @media (min-width: 570px) {
      white-space: nowrap;
    }
  }

  .donorInformation {
    grid-area: donorInformation;
  }

  .idLabel {
    grid-area: idLabel;
  }

  .id {
    grid-area: id;
  }

  .lastNameLabel {
    grid-area: lastNameLabel;
  }

  .lastName {
    grid-area: lastName;
  }

  .firstNameLabel {
    grid-area: firstNameLabel;
  }

  .firstName {
    grid-area: firstName;
  }

  .idVerifiedLabel {
    grid-area: idVerifiedLabel;
  }

  .idVerified {
    grid-area: idVerified;
  }

  .reasonForTestLabel {
    grid-area: reasonForTestLabel;
  }

  .reasonForTest {
    grid-area: reasonForTest;
  }

  .phoneNumberLabel {
    grid-area: phoneNumberLabel;
  }

  .phoneNumber {
    grid-area: phoneNumber;
  }

  .dateOfBirthLabel {
    grid-area: dateOfBirthLabel;
  }

  .dateOfBirth {
    grid-area: dateOfBirth;
  }

  .collectionInformation {
    grid-area: collectionInformation;
  }

  .monitorNameLabel {
    grid-area: monitorNameLabel;
  }

  .monitorName {
    grid-area: monitorName;
  }

  .collectionDateTimeLabel {
    grid-area: collectionDateTimeLabel;
  }

  .collectionDateTime {
    grid-area: collectionDateTime;
  }

  .employerLabel {
    grid-area: employerLabel;
  }

  .employer {
    grid-area: employer;
    white-space: pre-wrap;
  }

  .medicalReviewOfficerLabel {
    grid-area: medicalReviewOfficerLabel;
  }

  .medicalReviewOfficer {
    grid-area: medicalReviewOfficer;
    white-space: pre-wrap;
  }

  .note {
    margin: 2rem 0;
    grid-area: note;
    white-space: pre-wrap;
  }

  .signatureLabel {
    grid-area: signatureLabel;
  }

  .signature {
    grid-area: signature;
  }

  .signatureValidation {
    grid-area: signatureValidation;
  }

  .nameLabel {
    grid-area: nameLabel;
  }

  .name {
    grid-area: name;
  }
}
