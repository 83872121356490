.guide-form-label {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 2rem;
  align-items: start;
  grid-template-areas:
    "step2 step2"
    "step3 step4";

  > * {
    height: 100%;
    max-width: 100%;
  }

  .step1 {
    grid-area: step1;
  }

  .step2 {
    grid-area: step2;
  }

  .step3 {
    grid-area: step3;
  }

  .step4 {
    grid-area: step4;
  }

  @media (max-width: 570px) {
    grid-template-columns: 100%;
    grid-template-areas:
      "step1"
      "step2"
      "step3"
      "step4";
    grid-gap: 1rem 1rem;
  }
}

.guide-form-stick {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 2rem;
  grid-template-areas:
    "step5 step5"
    "wrong1 wrong2";

  .step5 {
    grid-area: step5;
  }

  .wrong1 {
    grid-area: wrong1;
  }

  .wrong2 {
    grid-area: wrong2;
  }

  @media (max-width: 570px) {
    grid-template-columns: 100%;
    grid-template-areas:
      "step5"
      "wrong1"
      "wrong2";
    grid-gap: 1rem;
  }
}

.image-text {
  font-size: 20px;
  position: relative;
  top: -30px;
  /* bottom: 0; */
  left: -55px;
  /* right: 0; */
  height: -moz-fit-content;
  /* height: fit-content; */
  margin: auto;
}

.text-on-image {
  margin: 0 auto !important;
  text-align: center !important;
}
