.cleaning-grid-row {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 1rem;
  grid-template-areas:
    "step1Label step2Label"
    "step1Image step2Image"
    "step3Label ."
    "step3Image .";

  .step1Label {
    grid-area: step1Label;
  }

  .step2Label {
    grid-area: step2Label;
  }

  .step3Label {
    grid-area: step3Label;
  }

  .step1Image {
    grid-area: step1Image;
  }

  .step2Image {
    grid-area: step2Image;
  }

  .step3Image {
    grid-area: step3Image;
  }

  img {
    max-width: 100%;
  }

  @media (max-width: 570px) {
    grid-template-columns: 1fr;
    grid-gap: 0.5rem 0.5rem;
    grid-template-areas:
      "step1Label"
      "step1Image"
      "."
      "."
      "step2Label"
      "step2Image"
      "."
      "."
      "step3Label"
      "step3Image";
  }
}
