.barcode-scan-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;

  .overlay {
    z-index: 1;
    position: fixed;
    width: 100%;
    text-align: center;

    .instructions {
      text-shadow: 0 0 5px #777;
    }

    &.top {
      bottom: 10rem;
    }

    &.bottom {
      bottom: 1.75rem;
    }
  }
}

.test-tube-image {
  max-width: 80%;
  max-height: 60%;
  box-shadow: 0 20px 60px -25px black;
  border: 8px solid white;
}

.exit-drug-test__link {
  z-index: 999;
}

.laser-scan-image {
  width: 45%;
}

.back-link {
  top: 60px;
  right: 30px;
  width: 120px;
  position: absolute;
}

.camera-scanner {
  z-index: 9;
}
