@import "reset-css";

body {
  font-family: "Amazon Ember", Helvetica, sans-serif;
  background: white;
}

video {
  border: 1px solid #999;
}

.StickyHeader {
  background-color: white;
  position: -webkit-sticky;
  //position: sticky;
  top: 0px;
  padding: 2rem 0 0.5rem 0;
  z-index: 1;
}

@media (max-width: 570px) {
  .StickyHeader {
    //position: fixed;
    width: 90%;
  }
}
