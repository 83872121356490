.card {
  border: 4px solid white;
  width: 100%;
  max-width: 450px;
  height: 100%;

  &.correct {
    border-color: #1768c9;

    .card-title {
      background-color: #1768c9;
      color: white;
      padding: 0 0 0.25rem 0;
    }
  }

  &.wrong {
    border-color: #bb2929;
  }

  .card-image {
     //max-width: 100%;
     width: 250px;
     height: 250px;
  }
}

