.phone-form {
  display: grid;
  grid-template-columns: max-content 15rem;
  gap: 0 1rem;
  align-items: center;
  grid-template-areas:
    "newPhoneLabel newPhone"
    ". phoneValidation"
    "confirmPhoneLabel confirmPhone"
    ". confirmPhoneValidation";

  @media (max-width: 570px) {
    grid-template-columns: 8rem 10rem;
  }

  .newPhoneLabel {
    grid-area: newPhoneLabel;
  }

  .newPhone {
    grid-area: newPhone;
  }

  .phoneValidation {
    grid-area: phoneValidation;
  }

  .confirmPhoneLabel {
    grid-area: confirmPhoneLabel;
  }

  .confirmPhone {
    grid-area: confirmPhone;
  }

  .confirmPhoneValidation {
    grid-area: confirmPhoneValidation;
  }
}

.barcode-form {
  display: grid;
  grid-template-columns: max-content 12rem;
  gap: 0 1rem;
  align-items: center;
  grid-template-areas:
    "barcodeLabel barcode"
    ". barcodeValidation";

  .barcodeLabel {
    grid-area: barcodeLabel;
  }

  .barcode {
    grid-area: barcode;
  }

  .barcodeValidation {
    grid-area: barcodeValidation;
  }
}

.date-of-birth-form {
  display: grid;
  grid-template-columns: max-content 15rem;
  gap: 0 1rem;
  align-items: center;
  grid-template-areas:
    "newDateOfBirthLabel newDateOfBirth"
    ". newDateOfBirthValidation";

  @media (max-width: 570px) {
    grid-template-columns: 8rem 10rem;
  }

  .newDateOfBirthLabel {
    grid-area: newDateOfBirthLabel;
  }

  .newDateOfBirth {
    grid-area: newDateOfBirth;
  }

  .newDateOfBirthValidation {
    grid-area: newDateOfBirthValidation;
  }
}

.barcode-form {
  display: grid;
  grid-template-columns: max-content 12rem;
  gap: 0 1rem;
  align-items: center;
  grid-template-areas:
    "barcodeLabel barcode"
    ". barcodeValidation";

  .barcodeLabel {
    grid-area: barcodeLabel;
  }

  .barcode {
    grid-area: barcode;
  }

  .barcodeValidation {
    grid-area: barcodeValidation;
  }
}
