.video-pre-label {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 2rem;
  align-items: start;
  grid-template-areas:
    "step1 step1"
    "step2 step3";

  > * {
    height: 100%;
    max-width: 100%;
  }

  .step1 {
    grid-area: step1;
  }

  .step2 {
    grid-area: step2;
  }

  .step3 {
    grid-area: step3;
  }
}

.quantisal-pre-label {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 2rem;
  align-items: start;
  grid-template-areas:
    "step1 step1"
    "step2 step3"
    "step4 step5";

  > * {
    height: 100%;
    max-width: 100%;
  }

  .step1 {
    grid-area: step1;
  }

  .step2 {
    grid-area: step2;
  }

  .step3 {
    grid-area: step3;
  }

  .step4 {
    grid-area: step4;
  }

  .step5 {
    grid-area: step5;
  }

  @media (max-width: 570px) {
    grid-template-columns: 100%;
    grid-template-areas:
      "step1"
      "step2"
      "step3"
      "step4"
      "step5";
    grid-gap: 1rem 1rem;
  }
}

.video-post-label {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 2rem;
  align-items: start;
  grid-template-areas:
    "step1 step2"
    "step3 step4";

  > * {
    height: 100%;
    max-width: 100%;
  }

  .step1 {
    grid-area: step1;
  }

  .step2 {
    grid-area: step2;
  }

  .step3 {
    grid-area: step3;
  }

  .step4 {
    grid-area: step4;
  }
}

.quantisal-post-label {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 2rem;
  align-items: start;
  grid-template-areas:
    "step6 step7"
    "step8 step9";

  > * {
    height: 100%;
    max-width: 100%;
  }

  .step6 {
    grid-area: step6;
  }

  .step7 {
    grid-area: step7;
  }

  .step8 {
    grid-area: step8;
  }

  .step9 {
    grid-area: step9;
  }

  @media (max-width: 570px) {
    grid-template-columns: 100%;
    grid-template-areas:
      "step6"
      "step7"
      "step8"
      "step9";
    grid-gap: 1rem 1rem;
  }
}

