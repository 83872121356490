.log-in-form {
  display: grid;
  grid-template-columns: 12rem 16rem;
  gap: 0 2rem;
  align-items: center;
  grid-template-areas:
    "emailLabel email"
    ". emailValidation"
    "phoneTailLabel phoneTail"
    ". phoneTailValidation"
    "languageLabel language";

  @media (max-width: 570px) {
    grid-template-columns:  10rem;
    grid-gap: 0 0.5rem;
  }

  .emailLabel {
    grid-area: emailLabel;
  }

  .email {
    grid-area: email;
  }

  .emailValidation {
    grid-area: emailValidation;
  }

  .phoneTailLabel {
    grid-area: phoneTailLabel;
  }

  .phoneTail {
    grid-area: phoneTail;
  }

  .phoneTailValidation {
    grid-area: phoneTailValidation;
  }

  .languageLabel {
    grid-area: languageLabel;
  }

  .language {
    grid-area: language;
  }
}

.input-field {
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 1.5;
  border: 2px solid #799294;
  z-index: 1;
  box-shadow: none;
  outline: none;
  color: #232f3e;
  background-color: #ffffff;
  font-family: "Amazon Ember", Helvetica, sans-serif;
  padding: 8px 8px 8px 8px;
  width: 100%;
  display: block;

  &.error-state {
    border-color: #cd3e3e;
  }
}
