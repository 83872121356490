.user-data-grid {
  display: grid;
  grid-template-columns: 14rem max-content max-content;
  grid-template-rows: max-content;
  gap: 0.5rem 1rem;
  grid-template-areas:
    "nameLabel name wrongName"
    "phoneLabel phone editPhone"
    "dateOfBirthLabel dateOfBirth editDateOfBirth";

  @media (max-width: 570px) {
    grid-template-columns: 9rem;
    grid-template-areas:
      "nameLabel ."
      "name wrongName"
      ". ."
      ". ."
      "phoneLabel ."
      "phone editPhone"
      ". ."
      ". ."
      "dateOfBirthLabel ."
      "dateOfBirth editDateOfBirth";
  }

  .nameLabel {
    grid-area: nameLabel;
  }

  .name {
    grid-area: name;
  }

  .wrongName {
    grid-area: wrongName;
  }

  .phoneLabel {
    grid-area: phoneLabel;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .css-hwp1se {
    align-self: flex-start;
    height: 28px;
    line-height: 28px;
  }

  .css-rq5i0p {
    height: 28px !important;
    line-height: 28px;
  }

  .phone {
    grid-area: phone;
  }

  .editPhone {
    grid-area: editPhone;
  }

  .dateOfBirthLabel {
    grid-area: dateOfBirthLabel;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .dateOfBirth {
    grid-area: dateOfBirth;
  }

  .editDateOfBirth {
    grid-area: editDateOfBirth;
  }
}

.consent-html {
  h1,
  h2,
  p {
    margin: 1rem 0;
    text-align: justify;
    line-height: 1.2;
  }

  h1 {
    font-size: 1.25rem;
    margin: 1rem 0 1.5rem 0;
    font-weight: bold;
  }

  h2 {
    font-size: 1rem;
    font-weight: bold;
  }

  p {
    font-size: 0.875rem;
  }
}
